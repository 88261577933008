/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// import * as React from "react"
import React, { useEffect, useState } from "react"
// import {  } from "gatsby-link"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { motion } from "framer-motion"

import Header from "./header"
import Footer from "./footer"
import "../style/style.scss"
import Aside from "./aside"
// import CookieConsent from "react-cookie-consent"
import { Helmet } from "react-helmet"




const Layout = ({
  children,
  subNavActive,
  pageName,
  nextPage = false,
  prevPage = false,
}) => {
  const [navOpen, setNavOpen] = useState(false)

  const toggleNav = () => {
    setNavOpen(!navOpen);
  }

  useEffect(() => {
    let action = false
    if (typeof window !== `undefined`) {
      const viewportWidth = window.innerWidth;
      if (viewportWidth > 1060) {
        setTimeout(() => {
          action = true
        }, 1000)
        document.addEventListener("wheel", event => {
          if (action) {
            if (event.deltaY > 0) {
              if (nextPage) {
                action = false
                navigate(nextPage)
              }
            } else {
              if (prevPage) {
                action = false
                navigate(prevPage)
              }
            }
            setTimeout(() => {
              action = true
            }, 5000)
          }
        })
      }
      else {
        var touchstartX = 0;
        var touchstartY = 0;
        var touchendX = 0;
        var touchendY = 0;

        var gesuredZone = document.querySelector('body');

        gesuredZone.addEventListener('touchstart', function (event) {
          touchstartX = event.screenX;
          touchstartY = event.screenY;

        }, false);

        gesuredZone.addEventListener('touchend', function (event) {
          touchendX = event.screenX;
          touchendY = event.screenY;

          handleGesure();
        }, false);

        function handleGesure() {
          var swiped = 'swiped: ';
          if (touchendX < touchstartX) {
            console.log(swiped + 'left!');
          }
          if (touchendX > touchstartX) {
            console.log(swiped + 'right!');
          }
          if (touchendY < touchstartY) {
            console.log(swiped + 'down!');
          }
          if (touchendY > touchstartY) {
            console.log(swiped + 'left!');
          }
          if (touchendY == touchstartY) {
            console.log('tap!');
          }
        }
        // document.addEventListener("scroll", event => {
        //   // console.log(window.innerHeight + window.pageYOffset);
        //   // console.log(document.body.offsetHeight - 20);
        //   // console.log((window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - 20))
        //   if ((window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - 20)) {
        //     // if (action) {
        //     // }
        //     if (!action) {
        //       setTimeout(() => {
        //         action = true
        //       }, 1000)
        //     }
        //     else {
        //       if (prevPage) {
        //         action = false
        //         navigate(prevPage)
        //       }
        //     }
        //   }

        // });
        // window.onscroll = function (ev) {
        // };
      }
    }
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <noscript>
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MMDPV35"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
      </noscript>
      <Helmet>
        <script type='text/javascript' ata-rh="true" data-react-helmet="true" >
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MMDPV35');`}
        </script>
      </Helmet>
      <Header navOpen={navOpen} toggleNav={toggleNav} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className={`main container ${pageName}`}>
        <motion.section
          initial={{
            opacity: 0,
            y: 200,
            x: 0,
          }}
          animate={{
            opacity: 1,
            y: 0,
            x: 0,
          }}
          exit={{
            opacity: 0,
            y: -200,
            x: 0,
          }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.4,
          }}
        >
          {children}
        </motion.section>
        <Aside />
      </main>

      <Footer subNavActive={subNavActive} />
      {/* <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Akceptuję"
        declineButtonText="Wyłącz"
        cookieName="gatsby-gdpr-google-tagmanager"
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        // declineButtonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={365}
      >
        Używamy plików cookie, aby zapewnić najlepszą jakość korzystania z naszej witryny.
      </CookieConsent> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
