import * as React from "react"
import { Link } from "gatsby"
const Aside = () => {
  return (
    <aside className="aside">
      <nav className="aside__nav">
        <ul>
          <li>
            <Link to="/" activeClassName="aside__nav--active">
              <span></span>
            </Link>
          </li>
          <li>
            <Link activeClassName="aside__nav--active" to="/google/">
              <span></span>
            </Link>
          </li>
          <li>
            <Link activeClassName="aside__nav--active" to="/facebook/">
              <span></span>
            </Link>
          </li>
          <li>
            <Link activeClassName="aside__nav--active" to="/pozycjonowanie/">
              <span></span>
            </Link>
          </li>
          <li>
            <Link
              activeClassName="aside__nav--active"
              to="/optymalizacja-konwersji/"
            >
              <span></span>
            </Link>
          </li>
          <li>
            <Link
              activeClassName="aside__nav--active"
              to="/analityka-internetowa/"
            >
              <span></span>
            </Link>
          </li>
          <li>
            <Link to="/case-study/" activeClassName="aside__nav--active">
              <span></span>
            </Link>
          </li>
          <li>
            <Link to="/promocja/" activeClassName="aside__nav--active">
              <span></span>
            </Link>
          </li>
          <li>
            <Link to="/o-mnie/" activeClassName="aside__nav--active">
              <span></span>
            </Link>
          </li>
          <li>
            <Link to="/formularz/" activeClassName="aside__nav--active">
              <span></span>
            </Link>
          </li>
          <li>
            <Link to="/kontakt/" activeClassName="aside__nav--active">
              <span></span>
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  )
}
export default Aside
