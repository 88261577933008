import * as React from "react"
import { Link } from "gatsby"
import NavArrow from "../images/nav_arrow.svg"

const Footer = ({ subNavActive }) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row jc-sb ai-center">
          <nav className="footer__nav">
            <ul className="row jc-sb ">
              <li className="footer__hasSubNav">
                <span
                  className={`${subNavActive ? "footer__nav--active" : ""}`}
                >
                  Usługi
                  <NavArrow />
                </span>
                <ul className="row jc-sb footer__subNav">
                  <li className="footer__nav--google">
                    <Link activeClassName="footer__nav--active" to="/google/">
                      Google
                      <br /> ADS
                    </Link>
                  </li>
                  <li className="footer__nav--facebook">
                    <Link activeClassName="footer__nav--active" to="/facebook/">
                      FACEBOOK
                      <br /> ADS
                    </Link>
                  </li>
                  <li className="footer__nav--position">
                    <Link
                      activeClassName="footer__nav--active"
                      to="/pozycjonowanie/"
                    >
                      POZYCJONOWANIE
                    </Link>
                  </li>
                  <li className="footer__nav--optymilize">
                    <Link
                      activeClassName="footer__nav--active"
                      to="/optymalizacja-konwersji/"
                    >
                      OPTYMALIZACJA <br />
                      KONWERSJI
                    </Link>
                  </li>
                  <li className="footer__nav--analyse">
                    <Link
                      activeClassName="footer__nav--active"
                      to="/analityka-internetowa/"
                    >
                      ANALITYKA
                      <br /> INTERNETOWA
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/case-study/" activeClassName="footer__nav--active">
                  Case Study
                </Link>
              </li>
              <li>
                <Link to="/promocja/" activeClassName="footer__nav--active">
                  Promocja
                </Link>
              </li>
              <li>
                <Link to="/o-mnie/" activeClassName="footer__nav--active">
                  O mnie
                </Link>
              </li>
            </ul>
          </nav>
          <div className="footer__contact">
            <Link to="/formularz/" activeClassName="footer__contact--active">
              Kontakt
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
